import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../utils/api";
import Title from "../../components/Title";
const Review = ({
  productId,
  reviews: initialReviews,
  averageRating: initialAverageRating,
  setReviews,
  setAverageRating,
}) => {
  const navigate = useNavigate();
  const [reviews, setlocalReviews] = useState(initialReviews || []);
  const [averageRating, setlocalAverageRating] = useState(
    initialAverageRating || 0
  );
  const [ratingCounts, setRatingCounts] = useState({
    5: 0,
    4: 0,
    3: 0,
    2: 0,
    1: 0,
  });
  const [featureRatings, setFeatureRatings] = useState({
    quality: 0,
    comfort: 0,
    value: 0,
    design: 0,
  });
  const [newReview, setNewReview] = useState({
    userName: "",
    comment: "",
    rating: 0,
  });
  const [activeTab, setActiveTab] = useState("reviews");
  const [isLoading, setIsLoading] = useState(false);
  const [reviewImages, setReviewImages] = useState([]);
  const minreview = 3; // Minimum reviews to show
  const [visibleReviews, setVisibleReviews] = useState(minreview); // State to track visible reviews

  // Function to handle "Show More" button click
  const handleShowMore = () => {
    setVisibleReviews((prev) => prev + minreview); // Show 5 more reviews
  };
  const handleShowLess = () => {
    setVisibleReviews(minreview);
  };
  // Check if user is logged in
  const isUserLoggedIn = () => {
    return !!localStorage.getItem("token");
  };

  // Fetch reviews for the product
  const fetchReviews = async () => {
    try {
      const response = await api.get(`/api/review/${productId}`);
      if (response.data.success) {
        console.log("Reviews:", response.data.reviews);
        setReviews(response.data.reviews);
        setlocalReviews(response.data.reviews);
        calculateAverageRating(response.data.reviews);
      }
    } catch (error) {
      console.error("Error fetching reviews:", error);
      toast.error("Failed to fetch reviews. Please try again.");
    }
  };

  // Calculate rating counts
  const calculateRatingCounts = (reviewsList) => {
    const counts = { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 };
    reviewsList.forEach((review) => {
      if (counts[review.rating] !== undefined) {
        counts[review.rating]++;
      }
    });
    return counts;
  };

  // Calculate feature ratings (dummy calculation for demo)
  const calculateFeatureRatings = () => {
    const baseRating = parseFloat(averageRating) || 3;
    return {
      quality: (baseRating + Math.random() * 0.8 - 0.4).toFixed(1),
      comfort: (baseRating + Math.random() * 0.8 - 0.4).toFixed(1),
      value: (baseRating + Math.random() * 0.8 - 0.4).toFixed(1),
      design: (baseRating + Math.random() * 0.8 - 0.4).toFixed(1),
    };
  };

  // Handle Review Submission
  const handleReviewSubmit = async (e) => {
    e.preventDefault();

    if (!isUserLoggedIn()) {
      toast.info("Please log in to submit a review");
      navigate("/login", { state: { returnUrl: `/product/${productId}` } });
      return;
    }

    if (!newReview.comment || newReview.rating === 0) {
      toast.error("Please provide both a comment and rating");
      return;
    }

    setIsLoading(true);
    try {
      const response = await api.post("/api/review/add", {
        productId,
        rating: newReview.rating,
        comment: newReview.comment,
      });

      if (response.data.success) {
        const newReviewWithName = {
          ...newReview,
          userName: response.data.userName,
          date: new Date().toISOString(),
        };

        const updatedReviews = [...reviews, newReviewWithName];
        setReviews(updatedReviews);
        setNewReview({ userName: "", comment: "", rating: 0 });

        // Update all derived stats
        calculateAverageRating(updatedReviews);
        setRatingCounts(calculateRatingCounts(updatedReviews));
        setFeatureRatings(calculateFeatureRatings());

        localStorage.setItem(
          `reviews_${productId}`,
          JSON.stringify(updatedReviews)
        );

        toast.success("Thank you for your review!");
        fetchReviews(); // Refresh reviews from the parent component
      }
    } catch (error) {
      console.error("Error submitting review:", error);
      toast.error("Failed to submit review. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Like a review
  const handleLikeReview = async (reviewId) => {
    if (!isUserLoggedIn()) {
      toast.info("Please log in to like a review");
      navigate("/login", { state: { returnUrl: `/product/${productId}` } });
      return;
    }

    try {
      const response = await api.post(`/api/review/${reviewId}/like`);
      if (response.data.success) {
        fetchReviews(); // Refresh reviews
        toast.success("Review liked successfully");
      }
    } catch (error) {
      console.error("Error liking review:", error);
      toast.error("Failed to like review. Please try again.");
    }
  };

  // Dislike a review
  const handleDislikeReview = async (reviewId) => {
    if (!isUserLoggedIn()) {
      toast.info("Please log in to dislike a review");
      navigate("/login", { state: { returnUrl: `/product/${productId}` } });
      return;
    }

    try {
      const response = await api.post(`/api/review/${reviewId}/dislike`);
      if (response.data.success) {
        fetchReviews(); // Refresh reviews
        toast.success("Review disliked successfully");
      }
    } catch (error) {
      console.error("Error disliking review:", error);
      toast.error("Failed to dislike review. Please try again.");
    }
  };

  // Mark a review as helpful
  const handleMarkHelpful = async (reviewId) => {
    if (!isUserLoggedIn()) {
      toast.info("Please log in to mark a review as helpful");
      navigate("/login", { state: { returnUrl: `/product/${productId}` } });
      return;
    }

    try {
      const response = await api.post(`/api/review/${reviewId}/helpful`);
      if (response.data.success) {
        fetchReviews(); // Refresh reviews
        toast.success("Review marked as helpful");
      }
    } catch (error) {
      console.error("Error marking review as helpful:", error);
      toast.error("Failed to mark review as helpful. Please try again.");
    }
  };

  // Report a review
  const handleReportReview = async (reviewId) => {
    if (!isUserLoggedIn()) {
      toast.info("Please log in to report a review");
      navigate("/login", { state: { returnUrl: `/product/${productId}` } });
      return;
    }

    try {
      const response = await api.post(`/api/review/${reviewId}/report`);
      if (response.data.success) {
        fetchReviews(); // Refresh reviews
        toast.success("Review reported successfully");
      }
    } catch (error) {
      console.error("Error reporting review:", error);
      toast.error("Failed to report review. Please try again.");
    }
  };

  // Calculate average rating
  const calculateAverageRating = (reviewsList) => {
    if (!reviewsList || reviewsList.length === 0) return 0;
    const sum = reviewsList.reduce((acc, review) => acc + review.rating, 0);
    const avg = (sum / reviewsList.length).toFixed(1);
    setAverageRating(avg);
    setlocalAverageRating(avg);
  };
  const calculateBarWidth = (count) => {
    const totalReviews = reviews.length;
    if (totalReviews === 0) return 0;
    return (count / totalReviews) * 100;
  };

  // Format date
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  // Render star rating component
  const StarRating = ({
    rating,
    interactive = false,
    onRatingChange = null,
  }) => {
    return (
      <div className="flex items-center">
        {[...Array(5)].map((_, i) => (
          <span
            key={i}
            onClick={() =>
              interactive && onRatingChange && onRatingChange(i + 1)
            }
            className={`${
              interactive ? "cursor-pointer" : ""
            } text-xl transition-colors duration-150 ${
              i < rating ? "text-yellow-500" : "text-gray-300"
            } hover:${interactive ? "text-yellow-400" : ""}`}
          >
            ★
          </span>
        ))}
        {rating > 0 && (
          <span className="ml-2 text-sm text-gray-600">
            {rating > 0 ? rating : ""}
          </span>
        )}
      </div>
    );
  };

  // Update derived states when reviews change
  useEffect(() => {
    setRatingCounts(calculateRatingCounts(reviews));
    setFeatureRatings(calculateFeatureRatings());
  }, [reviews]);

  useEffect(() => {
    fetchReviews();
  }, [productId]);

  return (
    <>
      {/* Description & Reviews Section */}
      <div className="mt-20">
        <div className=" text-center text-3xl py-2">
          <Title text1={"Ratings &"} text2={"Reviews"} />
        </div>

        <div className="mt-6 px-4 py-6">
          {/* Ratings & Reviews Header */}
          <div className="flex flex-col lg:flex-row justify-between items-start gap-8 border-b pb-8">
            <div className="w-full lg:w-1/3">
              {/* <h2 className="text-2xl font-bold mb-4">Ratings & Reviews</h2> */}
              <div className="flex items-center mb-2">
                <div className="flex items-baseline">
                  <span className="text-4xl font-bold">{averageRating}</span>
                  <span className="text-2xl ml-1">★</span>
                </div>
                <div className="ml-4 text-sm text-gray-600">
                  {reviews.length} {reviews.length === 1 ? "Rating" : "Ratings"}{" "}
                  &
                  <br />
                  {reviews.length} {reviews.length === 1 ? "Review" : "Reviews"}
                </div>
              </div>

              {/* Rating Bars */}
              <div className="space-y-2 mt-4">
                {[5, 4, 3, 2, 1].map((stars) => (
                  <div key={stars} className="flex items-center">
                    <div className="w-8 text-sm font-medium text-gray-600">
                      {stars} ★
                    </div>
                    <div className="flex-1 h-2 mx-2 bg-gray-200 rounded-full overflow-hidden">
                      <div
                        className="h-full bg-green-500 rounded-full"
                        style={{
                          width: `${calculateBarWidth(ratingCounts[stars])}%`,
                        }}
                      ></div>
                    </div>
                    <div className="w-10 text-sm text-gray-600 text-right">
                      {ratingCounts[stars]}
                    </div>
                  </div>
                ))}
              </div>

              {/* Rating Button */}
              <div className="mt-6">
                <button
                  onClick={() =>
                    document
                      .getElementById("reviewForm")
                      .scrollIntoView({ behavior: "smooth" })
                  }
                  className="w-full bg-white text-black border border-black px-4 py-2 rounded font-medium text-sm hover:bg-gray-100 transition-colors"
                >
                  Rate Product
                </button>
              </div>
            </div>
            <div className="w-full lg:w-2/3">
              {/* Customer Reviews Section */}
              <div>
                <div className="flex justify-end items-center mb-6">
                  {/* <h3 className="text-xl font-semibold">Customer Reviews</h3> */}
                  <div>
                    {isUserLoggedIn() ? (
                      <button
                        onClick={() =>
                          document
                            .getElementById("reviewForm")
                            .scrollIntoView({ behavior: "smooth" })
                        }
                        className="bg-black text-white px-4 py-2 rounded text-sm hover:bg-gray-800 transition-all"
                      >
                        Write a Review
                      </button>
                    ) : (
                      <button
                        onClick={() =>
                          navigate("/login", {
                            state: { returnUrl: `/product/${productId}` },
                          })
                        }
                        className="bg-black text-white px-4 py-2 rounded text-sm hover:bg-gray-800 transition-all"
                      >
                        Login to Write a Review
                      </button>
                    )}
                  </div>
                </div>

                {/* Review List */}
                <div className="space-y-6 mt-6">
                  {isLoading && reviews.length === 0 ? (
                    <div className="text-center py-10">
                      <p className="text-gray-500">Loading reviews...</p>
                    </div>
                  ) : reviews.length > 0 ? (
                    <>
                      {/* Display only the first `visibleReviews` reviews */}
                      {reviews.slice(0, visibleReviews).map((review, index) => (
                        <div
                          key={index}
                          className="border rounded-lg p-6 shadow-sm hover:shadow-md transition-shadow"
                        >
                          <div className="flex justify-between items-start">
                            <div>
                              <p className="text-gray-700 leading-relaxed">
                                {review.userName}
                              </p>
                              <div className="flex items-center mt-1 mb-3">
                                <StarRating rating={review.rating} />
                                {(review.createdAt || review.Date) && (
                                  <span className="ml-4 text-sm text-gray-500">
                                    {formatDate(review.createdAt)}
                                  </span>
                                )}
                              </div>
                            </div>
                            {/* Verified Purchase Badge */}
                            {/* {review.verifiedPurchase && (
              <span className="bg-green-100 text-green-800 text-xs font-semibold px-2.5 py-0.5 rounded">
                Verified Purchase
              </span>
            )} */}
                          </div>
                          <p className="font-medium text-lg mt-2">
                            {review.comment}
                          </p>

                          {/* Review Actions */}
                          {/* <div className="flex items-center mt-4 text-sm text-gray-500">
            <button
              onClick={() => handleLikeReview(review._id)}
              className="flex items-center hover:text-gray-700"
            >
              <svg
                className="w-4 h-4 mr-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"
                ></path>
              </svg>
              Helpful ({review.helpful?.length || 0})
            </button>
            <span className="mx-2">|</span>
            <button
              onClick={() => handleDislikeReview(review._id)}
              className="hover:text-gray-700"
            >
              Dislike ({review.dislikes?.length || 0})
            </button>
            <span className="mx-2">|</span>
            <button
              onClick={() => handleReportReview(review._id)}
              className="hover:text-gray-700"
            >
              Report
            </button>
          </div> */}
                        </div>
                      ))}

                      {/* Show More Button */}
                      {reviews.length > minreview && (
                        <div className="text-center flex justify-between items-center">
                          {visibleReviews < reviews.length && (
                            <button
                              onClick={handleShowMore}
                              className="bg-black text-white px-4 py-2 rounded text-sm hover:bg-gray-800 transition-all"
                            >
                              Show More
                            </button>
                          )}
                          {visibleReviews > minreview && (
                            <button
                              onClick={handleShowLess}
                              className="bg-black text-white px-4 py-2 rounded text-sm hover:bg-gray-800 transition-all"
                            >
                              Hide
                            </button>
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="text-center py-10 border rounded-lg">
                      <p className="text-gray-500 mb-4">
                        No reviews yet. Be the first to leave a review!
                      </p>
                      {!isUserLoggedIn() && (
                        <button
                          onClick={() =>
                            navigate("/login", {
                              state: { returnUrl: `/product/${productId}` },
                            })
                          }
                          className="bg-black text-white px-4 py-2 rounded text-sm hover:bg-gray-800 transition-all"
                        >
                          Login to Write a Review
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Review Form */}
          <div
            id="reviewForm"
            className="mt-12 border rounded-lg p-6 bg-gray-50 space-y-8"
          >
            <h3 className="text-lg font-semibold mb-4">Write a Review</h3>
            <form onSubmit={handleReviewSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Your Rating*
                </label>
                <StarRating
                  rating={newReview.rating}
                  interactive={true}
                  onRatingChange={(value) =>
                    setNewReview({ ...newReview, rating: value })
                  }
                />
              </div>

              <div>
                <label
                  htmlFor="comment"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Your Review*
                </label>
                <textarea
                  id="comment"
                  name="comment"
                  rows="4"
                  value={newReview.comment}
                  onChange={(e) =>
                    setNewReview({ ...newReview, comment: e.target.value })
                  }
                  placeholder="Share your thoughts about this product..."
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-black focus:ring-black border p-3 text-gray-700"
                ></textarea>
              </div>

              <button
                type="submit"
                disabled={isLoading}
                className={`w-full sm:w-auto bg-black text-white px-6 py-3 rounded font-medium text-sm transition-colors duration-200 hover:bg-gray-800 ${
                  isLoading ? "opacity-70 cursor-not-allowed" : ""
                }`}
              >
                {isLoading ? "Submitting..." : "Submit Review"}
              </button>

              {!isUserLoggedIn() && (
                <p className="text-sm text-gray-500 mt-2">
                  You'll be redirected to login before your review is submitted.
                </p>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Review;
