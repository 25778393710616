import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ShopContext } from "../context/ShopContext";
import RelatedProducts from "../components/RelatedProducts";
import { toast } from "react-toastify";
import api from "../utils/api";
import Review from "./productpage/Review";

const Product = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const { products, currency, addToCart } = useContext(ShopContext);
  const [productData, setProductData] = useState(false);
  const [image, setImage] = useState("");
  const [size, setSize] = useState("");
  const [reviews, setReviews] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const [isReviewsLoading, setIsReviewsLoading] = useState(true); // Add loading state for reviews

  // Fetch product data based on the productId from URL
  const fetchProductData = async () => {
    const product = products.find((item) => item._id === productId);
    if (product) {
      setProductData(product);
      setImage(product.image[0]);
    } else {
      navigate("/"); // Redirect if product not found
    }
  };

  // Calculate average rating
  const calculateAverageRating = (reviews) => {
    if (reviews.length === 0) {
      setAverageRating(0);
      return;
    }
    const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
    const avgRating = (totalRating / reviews.length).toFixed(1);
    setAverageRating(avgRating);
  };

  // Fetch product data and reviews on component mount
  useEffect(() => {
    fetchProductData();
  }, [productId, products]);

  // Render star rating component
  const StarRating = ({
    rating,
    interactive = false,
    onRatingChange = null,
  }) => {
    return (
      <div className="flex items-center">
        {[...Array(5)].map((_, i) => (
          <span
            key={i}
            onClick={() =>
              interactive && onRatingChange && onRatingChange(i + 1)
            }
            className={`${
              interactive ? "cursor-pointer" : ""
            } text-xl transition-colors duration-150 ${
              i < rating ? "text-yellow-500" : "text-gray-300"
            } hover:${interactive ? "text-yellow-400" : ""}`}
          >
            ★
          </span>
        ))}
        {rating > 0 && (
          <span className="ml-2 text-sm text-gray-600">
            {rating > 0 ? rating : ""}
          </span>
        )}
      </div>
    );
  };

  return productData ? (
    <div className="border-t-2 pt-10 transition-opacity ease-in duration-500 opacity-100">
      {/* Product Data */}
      <div className="flex gap-12 sm:gap-12 flex-col sm:flex-row">
        {/* Product Images */}
        <div className="flex-1 flex flex-col-reverse gap-3 sm:flex-row">
          <div className="flex sm:flex-col overflow-x-auto sm:overflow-y-scroll max-h-96 justify-between sm:justify-normal sm:w-[18.7%] w-full">
            {productData.image.map((item, index) => (
              <img
                onClick={() => setImage(item)}
                src={item}
                key={index}
                className={`w-[24%] sm:w-full sm:mb-3 flex-shrink-0 cursor-pointer border-2 ${
                  item === image ? "border-orange-500" : "border-transparent"
                } transition-all duration-200 hover:opacity-90`}
                alt=""
              />
            ))}
          </div>
          <div className="w-full sm:w-[80%]">
            <img
              className="w-full h-auto object-cover rounded-md shadow-sm"
              src={image}
              alt={productData.name}
            />
          </div>
        </div>

        {/* Product Info */}
        <div className="flex-1 text-wrap">
          <div className="flex flex-col sm:flex-row sm:items-center justify-between">
            <h1 className="font-medium text-2xl mt-2 break-words">
              {productData.name}
            </h1>
            <div className="flex items-center mt-2 sm:mt-0">
              <StarRating rating={averageRating} />
              <span className="ml-2 text-sm text-gray-600">
                ({reviews.length} {reviews.length === 1 ? "review" : "reviews"})
              </span>
            </div>
          </div>
          <p className="mt-5 text-3xl font-medium">
            {currency}
            {productData.price}
          </p>
          <div className="mt-5 text-gray-700 md:w-4/5 leading-relaxed">
            {productData.description.split("\n").map((line, index) => (
              <p key={index} className="mb-2">
                {line}
              </p>
            ))}
          </div>
          <div className="flex flex-col gap-4 my-8">
            <p className="font-medium">Select Size</p>
            <div className="flex flex-wrap gap-2">
              {productData.sizes.map((item, index) => (
                <button
                  onClick={() => setSize(item)}
                  className={`border py-2 px-4 rounded-md transition-all duration-200 ${
                    item === size
                      ? "border-orange-500 bg-orange-50 text-orange-700"
                      : "bg-gray-100 hover:bg-gray-200"
                  }`}
                  key={index}
                >
                  {item}
                </button>
              ))}
            </div>
          </div>
          <button
            onClick={() => {
              if (!size) {
                toast.error("Please select a size before adding to the cart!");
              } else {
                addToCart(productData._id, size);
                toast.success("Product added to cart!");
              }
            }}
            className="bg-black text-white px-8 py-3 text-sm font-medium rounded-md hover:bg-gray-800 transition-colors duration-200 active:bg-gray-700"
          >
            ADD TO CART
          </button>
          <hr className="mt-8 sm:w-4/5" />
        </div>
      </div>

      {/* Related Products */}
      <RelatedProducts
        category={productData.category}
        subCategory={productData.subCategory}
      />

      {/* Review Component */}
      <Review
        productId={productId}
        reviews={reviews}
        setReviews={setReviews}
        setAverageRating={setAverageRating}
        averageRating={averageRating}
        isReviewsLoading={isReviewsLoading} // Pass loading state
      />
    </div>
  ) : (
    <div className="flex justify-center items-center min-h-screen">
      <div className="animate-pulse text-gray-400">Loading product...</div>
    </div>
  );
};

export default Product;
