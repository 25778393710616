import React, { useContext, useState, useEffect } from "react";
import Title from "../components/Title";
import CartTotal from "../components/CartTotal";
import { ShopContext } from "../context/ShopContext";
import { toast } from "react-toastify";
import api from "../utils/api";
import Cart from "./Cart";

const PlaceOrder = () => {
  const [method, setMethod] = useState("razorpay");
  const {
    isLoggedIn,
    navigate,
    cartItems,
    setCartItems,
    getCartAmount,
    delivery_fee,
    getCartCount,
    products,
  } = useContext(ShopContext);

  // Form state for delivery info
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    street: "",
    city: "",
    state: "",
    pincode: "",
    country: "",
    phone: "",
  });

  // Coupon-related state
  const [couponCode, setCouponCode] = useState("");
  const [discount, setDiscount] = useState(0);

  // Fetch user's delivery information when the component mounts
  useEffect(() => {
    if (isLoggedIn) {
      fetchUserDeliveryInfo();
    }
  }, [isLoggedIn]);

  // Function to fetch user's delivery information
  const fetchUserDeliveryInfo = async () => {
    try {
      const { data } = await api.get("/api/user/delivery-info");
      if (data.success && data.user.deliveryInfo) {
        // Pre-fill the form with the user's saved delivery information
        setFormData(data.user.deliveryInfo);
      }
    } catch (error) {
      // console.error("Error fetching delivery information:", error);
      toast.error("Failed to fetch delivery information");
    }
  };

  // Function to save/update delivery information
  const saveDeliveryInfo = async () => {
    try {
      const { data } = await api.put("/api/user/delivery-info", {
        deliveryInfo: formData,
      });

      if (data.success) {
        toast.success("Delivery information saved successfully!");
      } else {
        toast.error(data.message || "Failed to save delivery information");
      }
    } catch (error) {
      // console.error("Error saving delivery information:", error);
      toast.error("Failed to save delivery information");
    }
  };

  // Handle form input changes
  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setFormData((data) => ({ ...data, [name]: value }));
  };

  // Function to apply a coupon code
  const onApplyCoupon = async () => {
    if (!couponCode.trim()) {
      toast.warning("Please enter a coupon code");
      return;
    }
    try {
      const { data } = await api.post("/api/coupon/apply", {
        couponCode,
        total: Number(getCartAmount()), // ensure numeric value
      });
      if (data.success) {
        // Convert discount to a number (if it isn't already)
        const discountValue = Number(data.discount);
        setDiscount(discountValue);
        toast.success("Coupon applied successfully");
        const baseTotal = Number(getCartAmount()) + Number(delivery_fee);
        const finalTotal = Math.max(baseTotal - discountValue, 0);
        // console.log("Base Total:", baseTotal);
        // console.log("Discount:", discountValue);
        // console.log("Final Total:", finalTotal);
      } else {
        toast.error(data.message || "Coupon invalid");
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to apply coupon");
    }
  };

  const initPay = (order) => {
    const options = {
      key: process.env.VITE_RAZORPAY_KEY_ID,
      amount: order.amount,
      currency: order.currency,
      name: "Order Payment",
      description: "Order Payment",
      order_id: order.id,
      receipt: order.receipt,
      handler: async (response) => {
        try {
          const { data } = await api.post(
            "/api/order/verifyRazorpay",
            response
          );
          if (data.success) {
            navigate("/orders");
            setCartItems({});
          }
        } catch (error) {
          console.error(error);
          toast.error(error);
        }
      },
    };
    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  const onSubmitHandler = async (event) => {
    if (Object.keys(cartItems).length === 0 || getCartCount < 1) {
      toast.error("Your cart is empty! Add some products to proceed.");
      return; // Exit the function if the cart is empty
    }
    // console.log(Object.keys(cartItems).length);
    // Proceed with payment gateway logic
    // console.log("Payment gateway here");
    // toast.success("Order placed successfully!");
    event.preventDefault();
    if (!isLoggedIn) {
      toast.warning("Please login first to place your order!", {
        position: "top-center",
        onClose: () => navigate("/login"),
        autoClose: 2000,
      });
      return;
    }
    try {
      let orderItems = [];
      for (const productId in cartItems) {
        for (const size in cartItems[productId]) {
          if (cartItems[productId][size] > 0) {
            const itemInfo = structuredClone(
              products.find((product) => product._id === productId)
            );
            if (itemInfo) {
              itemInfo.size = size;
              itemInfo.quantity = cartItems[productId][size];
              orderItems.push(itemInfo);
            }
          }
        }
      }

      const orderData = {
        address: formData,
        items: orderItems,
        amount: finalTotal,
      };

      switch (method) {
        case "cod": {
          const response = await api.post("/api/order/place", orderData);
          if (response.data.success) {
            setCartItems({});
            navigate("/orders");
          } else {
            toast.error(response.data.message);
          }
          break;
        }
        case "razorpay": {
          const responseRazorpay = await api.post(
            "/api/order/razorpay",
            orderData
          );
          if (responseRazorpay.data.success) {
            initPay(responseRazorpay.data.order);
          }
          break;
        }
        default:
          break;
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  // Calculate totals for display, explicitly converting values to numbers
  const baseTotal = Number(getCartAmount()) + Number(delivery_fee);
  const finalTotal = Math.max(baseTotal - discount, 0);

  return (
    <>
      <Cart />
      <form className="flex flex-col sm:flex-row justify-between gap-4 pt-5 sm:pt-14 min-h-[80vh] border-t">
        {/* Left Side: Delivery Information */}
        <div className="flex flex-col gap-4 w-full sm:max-w-[480px]">
          <div className="text-xl sm:text-2xl my-3">
            <Title text1={"DELIVERY"} text2={"INFORMATION"} />
          </div>
          <div className="flex gap-3">
            <input
              required
              onChange={onChangeHandler}
              name="firstName"
              value={formData.firstName}
              className="border border-gray-300 rounded py-1.5 px-3.5 w-full"
              type="text"
              placeholder="First name"
              pattern="[A-Za-z]+" // Only alphabets allowed
              title="Please enter only letters"
            />
            <input
              required
              onChange={onChangeHandler}
              name="lastName"
              value={formData.lastName}
              className="border border-gray-300 rounded py-1.5 px-3.5 w-full"
              type="text"
              placeholder="Last name"
              pattern="[A-Za-z]+" // Only alphabets allowed
              title="Please enter only letters"
            />
          </div>
          <input
            required
            onChange={onChangeHandler}
            name="email"
            value={formData.email}
            className="border border-gray-300 rounded py-1.5 px-3.5 w-full"
            type="email"
            placeholder="Email address"
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" // Basic email validation
            title="Please enter a valid email address"
          />
          <input
            required
            onChange={onChangeHandler}
            name="street"
            value={formData.street}
            className="border border-gray-300 rounded py-1.5 px-3.5 w-full"
            type="text"
            placeholder="Street"
          />
          <div className="flex gap-3">
            <input
              required
              onChange={onChangeHandler}
              name="city"
              value={formData.city}
              className="border border-gray-300 rounded py-1.5 px-3.5 w-full"
              type="text"
              placeholder="City"
              pattern="[A-Za-z]+" // Only alphabets allowed
              title="Please enter only letters"
            />
            <input
              onChange={onChangeHandler}
              name="state"
              value={formData.state}
              className="border border-gray-300 rounded py-1.5 px-3.5 w-full"
              type="text"
              placeholder="State"
              pattern="[A-Za-z]+" // Only alphabets allowed
              title="Please enter only letters"
            />
          </div>
          <div className="flex gap-3">
            <input
              required
              onChange={onChangeHandler}
              name="pincode"
              value={formData.pincode}
              className="border border-gray-300 rounded py-1.5 px-3.5 w-full"
              type="number"
              placeholder="Pincode"
              pattern="\d{6}" // Exactly 6 digits for pincode
              title="Please enter a valid 6-digit pincode"
            />
            <input
              required
              onChange={onChangeHandler}
              name="country"
              value={formData.country}
              className="border border-gray-300 rounded py-1.5 px-3.5 w-full"
              type="text"
              placeholder="Country"
              pattern="[A-Za-z]+" // Only alphabets allowed
              title="Please enter only letters"
            />
          </div>
          <input
            required
            onChange={onChangeHandler}
            name="phone"
            value={formData.phone}
            className="border border-gray-300 rounded py-1.5 px-3.5 w-full"
            type="number"
            placeholder="Phone"
            pattern="\d{10}" // Exactly 10 digits for phone number
            title="Please enter a valid 10-digit phone number"
          />
          <button
            type="button"
            onClick={saveDeliveryInfo}
            className="bg-white text-black border-black border-2 px-16 py-3 text-sm"
          >
            SAVE DELIVERY INFORMATION
          </button>
        </div>

        {/* Right Side: Cart Summary, Coupon & Payment Method */}
        <div className="mt-8">
          <div className="mt-8 min-w-80">
            <CartTotal />
          </div>

          {/* Coupon Code Section */}
          <div className="mt-4 border p-3">
            <h3 className="text-lg font-semibold mb-2">Have a coupon??</h3>
            <div className="flex gap-2">
              <input
                type="text"
                placeholder="Enter coupon code"
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
                className="border border-gray-300 rounded py-1 px-3 w-full"
              />
              <button
                type="button"
                onClick={onApplyCoupon}
                className="bg-blue-500 text-white px-4 py-1 rounded"
                disabled={discount > 0}
              >
                Apply
              </button>
            </div>
            {discount > 0 ? (
              <>
                <p className="mt-2 text-green-600">
                  Coupon applied! Discount: ₹{discount}
                </p>
                <p className="mt-1 font-semibold">Final Total: ₹{finalTotal}</p>
              </>
            ) : (
              <p className="mt-2 font-semibold">Total: ₹{baseTotal}</p>
            )}
          </div>

          {/* Payment Method Section */}
          <div className="mt-12">
            <Title text1={"PAYMENT"} text2={"METHOD"} />
            <div className="flex gap-3 flex-col lg:flex-row mt-4">
              <div
                onClick={() => setMethod("razorpay")}
                className="flex items-center gap-3 border p-2 px-3 cursor-pointer"
              >
                <p
                  className={`min-w-3.5 h-3.5 border rounded-full ${
                    method === "razorpay" ? "bg-green-400" : ""
                  }`}
                ></p>
                <p>PREPAID</p>
              </div>
            </div>

            <div className="w-full text-end mt-8">
              <button
                onClick={onSubmitHandler}
                type="button"
                className="bg-black text-white px-16 py-3 text-sm"
              >
                PLACE ORDER
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default PlaceOrder;
