import React, { useContext, useEffect, useState } from "react";
import { ShopContext } from "../context/ShopContext";
import Title from "../components/Title";
import api from "../utils/api";

const Orders = () => {
  const { token, currency } = useContext(ShopContext);

  const [orderData, setOrderData] = useState([]);
  const [canceledOrders, setCanceledOrders] = useState([]);

  const loadOrderData = async () => {
    try {
      if (!token) {
        return null;
      }

      const response = await api.post("/api/order/userorders", {});
      if (response.data.success) {
        let allOrdersItem = [];
        let allCanceledOrders = [];
        response.data.orders.forEach((order) => {
          order.items.forEach((item) => {
            allOrdersItem.push(item);
          });
          if (order.status === "canceled" || order.status === "failed") {
            allCanceledOrders.push(order);
          }
        });
        setOrderData(allOrdersItem);
        setCanceledOrders(allCanceledOrders);
      }
    } catch (error) {
      console.error("Error loading order data:", error);
    }
  };

  useEffect(() => {
    loadOrderData();
  }, [token]);

  return (
    <div>
      <Title title="Your Orders" />
      <div>
        <h2>Active Orders</h2>
        {orderData.length > 0 ? (
          orderData.map((order, index) => (
            <div key={index}>
              <p>Order ID: {order._id}</p>
              <p>Product: {order.productName}</p>
              <p>
                Price: {order.price} {currency}
              </p>
              <p>Status: {order.status}</p>
            </div>
          ))
        ) : (
          <p>No active orders found.</p>
        )}
      </div>
      <div>
        <h2>Canceled Orders</h2>
        {canceledOrders.length > 0 ? (
          canceledOrders.map((order, index) => (
            <div key={index}>
              <p>Order ID: {order._id}</p>
              <p>Product: {order.productName}</p>
              <p>
                Price: {order.price} {currency}
              </p>
              <p>Status: {order.status}</p>
            </div>
          ))
        ) : (
          <p>No canceled orders found.</p>
        )}
      </div>
      {/* <div>
        <h2>Track Order</h2>
        <p>Track your order status here.</p>
        // Add track order box code here
      </div> */}
    </div>
  );
};

export default Orders;
